<template>
  <div>
    <div class="ll-box">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
              icon="HomeIcon"
              size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Configuration') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active >
            SLA
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <!-- Add Btn -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="info"
          :to="{ name: 'create-sla' }"
          v-permission="[189]"
        >
          <span class="text-nowrap">{{ $t('Create') }}</span>
        </b-button>
      </div>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                v-if="false"
                is-text
                v-b-toggle.filterS
                class="ll-outline">
                Filter
              </b-input-group-prepend>
              <b-form-input
                v-model="slaName"
                class="searchInput"
                placeholder="Please Enter SLA ID"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title=""
      right
      shadow
      bg-variant="white"
      backdrop>
      <div class="px-3">
        <b-form-group
          class="g-label ll-bform"
          label="Status Type"
          label-for="status"
        >
          <v-select
            id="status"
            v-model="statysType"
            :options="statusTypeList"
            label="name"
            @input="getStatusType"
            placeholder="Please Enter Status Type"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          class="g-label ll-bform"
          label="Is Active"
          label-for="isActive"
        >
          <v-select
            id="isActive"
            :options="statusList"
            label="status"
            placeholder=""
            @input="getStatus"
          ></v-select>
        </b-form-group>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            {{ $t('Clear') }}
          </b-button>
          <b-button variant="info"
            v-b-toggle.filterS
            @click="filterSearch">
            {{ $t('Search') }}
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <!-- table -->
    <b-table
      ref="refUserListTable"
      class="position-relative ll-table"
      :items="rows"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="onSortChange"
      v-permission="[188]"
      :sticky-header="true"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(isEnabled)="row">
        <b-badge
          :variant="row.value ? 'light-info' : 'light-secondary'"
          class="ll-badge">
          <span>{{ row.value ? 'Active' : 'Inactive' }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(action)="data">
        <feather-icon
          :id="`${data.item.id}`"
          icon="EyeIcon"
          size="16"
          class="mx-1"
          @click="$router.push({ name: 'sla-detail', params: { id: data.item.slaName }})"
          v-permission="[190]"
        >
        <b-tooltip
          title="View Details"
          :target="`${data.item.id}`"
        />
        </feather-icon>
      </template>
    </b-table>
    <!-- show pages -->
    <div class="d-flex justify-content-between flex-wrap ll-p">
      <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> of {{ totalSize }} entries </span>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BFormSelect, BButton, BTooltip, VBToggle,
  BCard, BCardBody, BTable, BRow, BCol, BFormInput, BFormGroup, BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem, BBadge, BSpinner,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import vSelect from 'vue-select'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormInput,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'customerName', label: this.$t('Customer Name'), sortable: true },
        { key: 'slaName', label: this.$t('SLA ID'), sortable: true },
        { key: 'description', label: this.$t('SLA Desciption'), sortable: true },
        { key: 'effectiveDate', label: this.$t('Effective date'), sortable: true },
        { key: 'workdayFlag', label: this.$t('Work day only'), sortable: true },
        { key: 'isEnabled', label: this.$t('Status'), sortable: true },
        { key: 'action', label: this.$t('Action'), class: 'll-action' },
      ],
      rows: [],
      slaName: '',
      condition: {
        page: '1',
        size: '50',
        orderBy: 'id',
        orderMole: 1,
        slaName: null,
        isEnabled: null,
      },
      statusList: [
        { value: true, status: 'active' },
        { value: false, status: 'inactive' },
      ],
      statysType: '',
      statusTypeList: [],
      isBusy: true,
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 首次查询
    getList() {
      this.$http.post('slaConfig/search', this.condition).then(res => {
        // console.log(res.data.data)
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.rows = info.content
          this.page = info.page
          this.size = info.size
          this.totalSize = info.totalSize
          this.totalPage = info.totalPage
          this.isBusy = false
        }
      }).catch(error => console.log(error))
    },
    onRowClick() {
    },
    onSearch() {
    },
    // 根据SLAID查询
    searchById() {
      this.condition.slaName = this.slaName.trim()
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      // console.log(orderBy, orderMole)
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    getStatus(s) {
      this.condition.isEnabled = s.value
    },
    getStatusType() {},
    // 条件过滤搜索
    filterSearch() {
      this.getList()
    },
    onClear() {
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: absolute;
  bottom: 30px;
  right: 10px;
} */
.btn-mrg{
  margin-left: 10px;
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-outline div{
  background: #aaa;
  color: #fff;
  width: 60px;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
</style>
